import type { MemberBenefit } from '../MemberBenefits'

export const defaultMemberBenefitsDetails: MemberBenefit[] = [
  {
    text: 'Spend wisely',
    image: {
      alt: '',
      id: '',
      caption: '',
      dimensions: { height: '', width: '' },
      renditions: [],
      src: 'https://media.product.which.co.uk/prod/images/original/164bab03f71d-iconspending.png',
      sources: [
        {
          media: '',
          type: '',
          srcset:
            'https://media.product.which.co.uk/prod/images/ar_1to1_50x50/164bab03f71d-iconspending.webp 50w, https://media.product.which.co.uk/prod/images/ar_1to1_100x100/164bab03f71d-iconspending.webp 100w, https://media.product.which.co.uk/prod/images/ar_1to1_150x150/164bab03f71d-iconspending.webp 150w, https://media.product.which.co.uk/prod/images/ar_1to1_200x200/164bab03f71d-iconspending.webp 200w',
          sizes: '62px',
        },
      ],
    },
    list: [
      {
        title: 'Trusted reviews',
        text: "Access the UK's largest range of expert product reviews",
      },
      {
        title: 'Best Buys & Don’t Buys',
        text: 'Save time and money by getting it right first time',
      },
      {
        title: 'Great Value & Eco Buys',
        text: 'Buy better for your pocket and for the planet',
      },
    ],
  },
  {
    text: 'Get tailored answers',
    image: {
      alt: '',
      id: '',
      caption: '',
      dimensions: { height: '', width: '' },
      renditions: [],
      src: 'https://media.product.which.co.uk/prod/images/original/dd9d828c3304-iconanswers.png',
      sources: [
        {
          media: '',
          type: '',
          srcset:
            'https://media.product.which.co.uk/prod/images/ar_1to1_50x50/dd9d828c3304-iconanswers.webp 50w, https://media.product.which.co.uk/prod/images/ar_1to1_100x100/dd9d828c3304-iconanswers.webp 100w, https://media.product.which.co.uk/prod/images/ar_1to1_150x150/dd9d828c3304-iconanswers.webp 150w, https://media.product.which.co.uk/prod/images/ar_1to1_200x200/dd9d828c3304-iconanswers.webp 200w',
          sizes: '62px',
        },
      ],
    },
    list: [
      {
        title: '1-to-1 buying advice',
        text: 'Reach decisions quicker via personalised online chats',
      },
      {
        title: 'Simple product filtering',
        text: 'Quickly compare products to find the best for you',
      },
      {
        title: 'Expert and impartial',
        text: 'Get independent advice from our team of experts',
      },
    ],
  },
  {
    text: 'Stay in the know',
    image: {
      alt: '',
      id: '',
      caption: '',
      dimensions: { height: '', width: '' },
      renditions: [],
      src: 'https://media.product.which.co.uk/prod/images/original/dd9d828c3304-iconanswers.png',
      sources: [
        {
          media: '',
          type: '',
          srcset:
            'https://media.product.which.co.uk/prod/images/ar_1to1_50x50/fd70ced35f18-iconstayinformed.webp 50w, https://media.product.which.co.uk/prod/images/ar_1to1_100x100/fd70ced35f18-iconstayinformed.webp 100w, https://media.product.which.co.uk/prod/images/ar_1to1_150x150/fd70ced35f18-iconstayinformed.webp 150w, https://media.product.which.co.uk/prod/images/ar_1to1_200x200/fd70ced35f18-iconstayinformed.webp 200w',
          sizes: '62px',
        },
      ],
    },
    list: [
      {
        title: 'Magazine, app & newsletters',
        text: 'Keep up with our news, views and reviews wherever you are',
      },
      {
        title: 'Latest recalls and scams',
        text: 'Use our regular updates to keep out of harm’s way',
      },
      {
        title: 'Consumer rights',
        text: 'Use our tools and guidance to navigate consumer issues',
      },
    ],
  },
]

export const testMemberFeaturesDetails: MemberBenefit[] = [
  {
    text: 'Trusted reviews',
    image: {
      alt: '',
      id: '',
      caption: '',
      dimensions: { height: '', width: '' },
      renditions: [],
      src: 'https://media.product.which.co.uk/prod/images/original/e75c9ee106b2-reviews-member-feature.png',
      sources: [
        {
          media: '',
          type: '',
          srcset:
            'https://media.product.which.co.uk/prod/images/ar_1to1_50x50/e75c9ee106b2-reviews-member-feature.webp 50w, https://media.product.which.co.uk/prod/images/ar_1to1_100x100/e75c9ee106b2-reviews-member-feature.webp 100w, https://media.product.which.co.uk/prod/images/ar_1to1_150x150/e75c9ee106b2-reviews-member-feature.webp 150w, https://media.product.which.co.uk/prod/images/ar_1to1_200x200/e75c9ee106b2-reviews-member-feature.webp 200w',
          sizes: '62px',
        },
      ],
    },
    list: [
      {
        title: null,
        text: 'Best Buys and Don’t Buys from 100% independent lab tests and expert assessments',
      },
      {
        title: null,
        text: 'Save money with Great Value recommendations',
      },
      {
        title: null,
        text: 'Find Eco Buys to shop sustainably',
      },
      {
        title: null,
        text: 'Reveal Recommended & Eco Providers for everyday services',
      },
    ],
  },
  {
    text: 'Which? magazine & app',
    image: {
      alt: '',
      id: '',
      caption: '',
      dimensions: { height: '', width: '' },
      renditions: [],
      src: 'https://media.product.which.co.uk/prod/images/original/be93a5e59d3a-magazine-member-feature.png',
      sources: [
        {
          media: '',
          type: '',
          srcset:
            'https://media.product.which.co.uk/prod/images/ar_1to1_50x50/be93a5e59d3a-magazine-member-feature.webp 50w, https://media.product.which.co.uk/prod/images/ar_1to1_100x100/be93a5e59d3a-magazine-member-feature.webp 100w, https://media.product.which.co.uk/prod/images/ar_1to1_150x150/be93a5e59d3a-magazine-member-feature.webp 150w, https://media.product.which.co.uk/prod/images/ar_1to1_200x200/be93a5e59d3a-magazine-member-feature.webp 200w',
          sizes: '62px',
        },
      ],
    },
    list: [
      {
        title: null,
        text: 'UK’s bestselling monthly magazine with the latest test results, in-depth surveys and investigations',
      },
      {
        title: null,
        text: 'An app with all our product reviews and recommendations - shortlist the products that appeal to you and make side-by-side comparisons',
      },
    ],
  },
  {
    text: '1-to-1 buying advice',
    image: {
      alt: '',
      id: '',
      caption: '',
      dimensions: { height: '', width: '' },
      renditions: [],
      src: 'https://media.product.which.co.uk/prod/images/original/56e52179e7f0-1-1-advice-member-feature.png',
      sources: [
        {
          media: '',
          type: '',
          srcset:
            'https://media.product.which.co.uk/prod/images/ar_1to1_50x50/56e52179e7f0-1-1-advice-member-feature.webp 50w, https://media.product.which.co.uk/prod/images/ar_1to1_100x100/56e52179e7f0-1-1-advice-member-feature.webp 100w, https://media.product.which.co.uk/prod/images/ar_1to1_150x150/56e52179e7f0-1-1-advice-member-feature.webp 150w, https://media.product.which.co.uk/prod/images/ar_1to1_200x200/56e52179e7f0-1-1-advice-member-feature.webp 200w',
          sizes: '62px',
        },
      ],
    },
    list: [
      {
        title: null,
        text: 'Chat with us online for personalised 1-to-1 buying advice',
      },
      {
        title: null,
        text: 'We can give you our expert take on any products we’ve tested, so you can make informed decisions quicker',
      },
    ],
  },
]
