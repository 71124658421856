import type { ComponentPropsWithoutRef, FunctionComponent } from 'react'
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  ButtonLink,
  ChevronRightIcon,
  List,
  ListItem,
  Picture,
  SignupButton,
  Typography,
} from '@which/seatbelt'

import { useFocusRing } from '@react-aria/focus'
import type { GridListProps as AriaGridListProps } from '@react-aria/gridlist'
import { useGridList, useGridListItem } from '@react-aria/gridlist'
import { mergeProps } from '@react-aria/utils'
import { Item } from '@react-stately/collections'
import type { ListProps, ListState } from '@react-stately/list'
import { useListState } from '@react-stately/list'
import classnames from 'classnames'

import type { ImageWithSources } from '../../../../generated/frontend'
import { defaultMemberBenefitsDetails } from './constants/memberBenefits'
import styles from './MemberBenefits.module.scss'

export const MemberBenefits: FunctionComponent<Props> = ({
  title,
  overlineText,
  className,
  headingId,
  isLoggedIn,
  caption,
  calloutText,
  buttonLink,
  details = defaultMemberBenefitsDetails,
  ...rest
}) => {
  return (
    <article aria-labelledby={headingId} className={classnames(styles.panel, className)} {...rest}>
      <div className={styles.panelHeadingWrapper}>
        <Typography id={headingId} tag="h2" textStyle="title-600" className={styles.panelTitle}>
          {title}
        </Typography>
        {!isLoggedIn && (
          <Typography textStyle="overline" className={styles.panelOverline}>
            {overlineText}
          </Typography>
        )}
      </div>
      <GridList
        memberBenefits={details}
        aria-labelledby={headingId}
        isLoggedIn={isLoggedIn}
        calloutText={calloutText}
      >
        {details.map((item, index) => (
          <Item textValue={` ${item.text} - press to read more`} key={index}>
            <MemberBenefitsRow {...item} />
          </Item>
        ))}
      </GridList>
      {isLoggedIn ? (
        <ButtonLink
          href={'/account'}
          className={styles.signUpButton}
          data-which-id="benefits-panel-button"
          aria-label="myAccount"
        >
          <ChevronRightIcon /> My Account
        </ButtonLink>
      ) : (
        <SignupButton
          caption={caption}
          buttonLink={buttonLink}
          data-which-id="benefits-panel-button"
        />
      )}
    </article>
  )
}

///////// IMPLEMENTATION /////////

const GridList: FunctionComponent<GridListProps> = ({
  memberBenefits,
  isLoggedIn,
  calloutText,
  ...props
}) => {
  const [showOverlay, setShowOverlay] = useState<boolean>(false)
  const [selectedBenefit, setSelectedBenefit] = useState<number | null>(null)
  const stateProps = props as ListProps<HTMLUListElement>
  const state = useListState(stateProps)
  const listRef = useRef<HTMLUListElement>(null)
  const overlayRef = useRef<HTMLDivElement>(null)
  const listItemRefs = useRef<HTMLLIElement | null[]>([])

  useEffect(() => {
    if (selectedBenefit !== null) {
      setShowOverlay(true)
    } else {
      setShowOverlay(false)
    }
  }, [selectedBenefit])

  const onAction: AriaGridListProps<HTMLUListElement>['onAction'] = (key) => {
    setSelectedBenefit(typeof key === 'number' ? key : parseInt(key))
  }

  const onClose = () => {
    setShowOverlay(false)
  }

  const onTransitionEnd = () => {
    if (showOverlay) {
      overlayRef.current?.focus()
    } else {
      listItemRefs.current[selectedBenefit || 0]?.focus()
      setSelectedBenefit(null)
    }
  }

  const { gridProps } = useGridList({ onAction, selectionMode: 'none', ...props }, state, listRef)

  return (
    <div className={styles.gridListWrapper}>
      <ul {...gridProps} ref={listRef} className={styles.gridList} aria-hidden={showOverlay}>
        {Array.from(state.collection).map((item, index) => (
          <GridListItem
            focusable={!showOverlay}
            key={item.key}
            item={item}
            state={state}
            ref={(el) => (listItemRefs.current[index] = el)}
          />
        ))}
      </ul>
      <Overlay
        hidden={!showOverlay}
        ref={overlayRef}
        onClose={onClose}
        memberBenefit={memberBenefits[selectedBenefit || 0]}
        onTransitionEnd={onTransitionEnd}
      />
      {!isLoggedIn && calloutText && <span className={styles.callout}>{calloutText}</span>}
    </div>
  )
}

const GridListItem = forwardRef<HTMLLIElement, GridListItemProps>(
  ({ item, state, focusable }, ref) => {
    const internalRef = useRef<HTMLLIElement>(null)
    useImperativeHandle<HTMLLIElement | null, HTMLLIElement | null>(ref, () => internalRef.current)

    const { rowProps, gridCellProps, isPressed } = useGridListItem(
      { node: item },
      state,
      internalRef
    )

    const { isFocusVisible, focusProps } = useFocusRing()

    return (
      <li
        ref={internalRef}
        data-which-id="benefits-panel-link"
        {...mergeProps(rowProps, focusProps)}
        className={classnames(styles.listItem, isPressed && styles.listItemPressed)}
        {...(!focusable && { tabIndex: -1 })}
      >
        <div
          className={classnames(isFocusVisible && styles.listItemFocusVisible)}
          {...gridCellProps}
        >
          {item.rendered}
        </div>
      </li>
    )
  }
)

const MemberBenefitsRow: FunctionComponent<MemberBenefitsButtonProps> = ({ text, image }) => (
  <div className={styles.benefitsRow}>
    <Picture
      src={image.src}
      alt={image.alt}
      sources={image.sources}
      aspectRatioMain="one-to-one"
      height="62"
      width="62"
      className={styles.icon}
      imageClassName={styles.iconImage}
      aria-hidden
    />
    <div className={styles.benefitsRowTextWrapper}>
      <Typography tag="span" textStyle="body-intro" className={styles.benefitsRowText}>
        {text}
      </Typography>
    </div>
    <ArrowRightIcon />
  </div>
)

const Overlay = forwardRef<HTMLDivElement, OverlayProps>(
  ({ onClose, memberBenefit: { image, text, list }, className, hidden, ...rest }, ref) => {
    const { isFocusVisible, focusProps } = useFocusRing()

    return (
      <section
        tabIndex={-1}
        className={classnames(
          styles.overlay,
          isFocusVisible && styles.overlayFocusVisible,
          hidden && styles.overlayHidden,
          className
        )}
        ref={ref}
        aria-label={`${text} member benefit info`}
        aria-hidden={hidden}
        {...focusProps}
        {...rest}
      >
        <div className={styles.overlayHeader}>
          <Button
            onClick={onClose}
            appearance="secondary"
            className={styles.overlayButton}
            aria-label="Back to list"
            {...(hidden && { tabIndex: -1 })}
          >
            <ArrowLeftIcon
              alt="Back to member benefits list"
              className={styles.overlayButtonIcon}
            />
          </Button>
          <Picture
            src={image.src}
            alt={image.alt}
            sources={image.sources}
            aspectRatioMain="one-to-one"
            height="62"
            width="62"
            className={styles.icon}
            imageClassName={styles.iconImage}
            aria-hidden
          />
          <Typography tag="span" textStyle="body-intro" className={styles.benefitsRowText}>
            {text}
          </Typography>
        </div>
        <List className={styles.overlayList}>
          {list.map(({ text: listItemText, title }, index) => (
            <ListItem icon="tick" key={index} className={styles.overlayListItem}>
              {title && (
                <Typography textStyle="body-intro" tag="h3">
                  {title}
                </Typography>
              )}
              {listItemText && <Typography tag="p">{listItemText}</Typography>}
            </ListItem>
          ))}
        </List>
      </section>
    )
  }
)

export type MemberBenefit = {
  text: string
  image: ImageWithSources
  list: {
    title: string | null
    text: string | null
  }[]
}

export type Props = {
  title: string
  overlineText: string
  headingId: string
  isLoggedIn?: boolean
  caption?: string
  calloutText?: string
  buttonLink?: {
    text: string
    href: string
  }
  details?: MemberBenefit[]
} & ComponentPropsWithoutRef<'div'>

type GridListProps = {
  memberBenefits: MemberBenefit[]
  isLoggedIn?: boolean
  calloutText?: string
} & AriaGridListProps<HTMLUListElement>

type GridListItemProps = {
  item: any
  state: ListState<HTMLUListElement>
  focusable: boolean
}

type MemberBenefitsButtonProps = {
  text: string
  image: ImageWithSources
}

type OverlayProps = {
  onClose: Parameters<typeof Button>[0]['onClick']
  memberBenefit: MemberBenefit
  hidden: boolean
} & ComponentPropsWithoutRef<'div'>
